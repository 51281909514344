import { PropsWithChildren } from 'react'
import { ListItem } from './ListItem'
import classes from './dashedlist.module.scss'

type ItemProps = {
  list_text: string
  list_variant: string
  list_link: {
    cached_url: string
    url: string
  }
}

export type DashedListWrapperProps = React.ComponentProps<'ul'> & {
  items: ItemProps[]
}

export const DashedList: React.FC<PropsWithChildren<DashedListWrapperProps>> = ({ ...props }) => {
  const items = props.items

  return (
    <ul className={ classes.dashed_ul }>
      {items.map((item, i: number) => {
        return (
          <ListItem
            key={i}
            text={item?.list_text}
            variant={item?.list_variant}
            link={item?.list_link?.cached_url ? item?.list_link?.cached_url : '' }
          />
        )
      })}
    </ul>
  )
}
