import { ISbRichtext } from 'storyblok-js-client'
import richTextResolver from './richTextResolver'

export type RichTextContent = ISbRichtext

type DangerousInnerHTMLProps = {
  dangerouslySetInnerHTML: {
    __html: string
  }
}

export const useRichText = (data: ISbRichtext): DangerousInnerHTMLProps => ({
  dangerouslySetInnerHTML: {
    __html: richTextResolver(data),
  },
})