import FormData from 'form-data'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import * as yup from 'yup'
import { Button } from '../Button'
import { FormFieldsContainer } from './FormFields/FormFieldsContainer'
import { TextAreaFormField } from './FormFields/TextAreaFormField'
import { TextFormField } from './FormFields/TextFormField'
import formStyles from './forms.module.scss'

export type CustomFormProps = {
  validationSchema?: yup.AnyObjectSchema
  slice: {
    re_direct_to: {
      cached_url: string
    }
    component: string
  }
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

const initialValuesProps = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  company: '',
  companySize: '',
  websiteUrl: '',
  projectInfo: '',
  fileName: '',
}

const validationSchemaProps = yup.object({
  firstname: yup
    .string()
    .required('First name is required.'),
  lastname: yup
    .string()
    .required('Last name is required.'),
  email: yup.string().email('Invalid email').required('Email is required.'),
  phoneNumber: yup
    .string()
    .required('Phone number is required'),
})


export const AtomixFullForm: React.FC<PropsWithChildren<CustomFormProps>> = ({ slice }) => {
  const router = useRouter()
  const initialValues = initialValuesProps || new FormData()

  return (
    <>
      <div className={formStyles.formContainer}>
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchemaProps}

          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true)
            // make async call
            await sleep(500)

            const formData = new FormData()

            for (const [key, value] of Object.entries(data)) {
              formData.append(key, value)
            }

            fetch('https://getform.io/f/e174a62b-9956-4399-a571-a2522e7bc08d', {
              method: 'POST',
              // @ts-ignore
              body: formData,
            })
              .then((response) => {
                return response.status === 200 && router.push(slice.re_direct_to.cached_url)
              })
              .catch(error => console.log(error))
            await sleep(3000)

            setSubmitting(false)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={formStyles.formWrapper}>
                {
                  <TextFormField
                    label={'First name'}
                    name={'firstname'}
                    placeholder={''}
                    type={'text'}
                    required={true}
                    fullWidth={false}
                  />
                }
                {
                  <TextFormField
                    label={'Last name'}
                    name={'lastname'}
                    placeholder={''}
                    type={'text'}
                    required={true}
                    fullWidth={false}
                  />
                }
              </div>
              <div className={formStyles.formWrapper}>
                {
                  <TextFormField
                    label={'Email'}
                    name={'email'}
                    placeholder={''}
                    type={'email'}
                    required={true}
                    fullWidth={false}
                  />
                }
                {
                  <TextFormField
                    label={'Phone number'}
                    name={'phoneNumber'}
                    placeholder={''}
                    required={true}
                    fullWidth={false}
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                }
              </div>
              <div className={formStyles.formWrapper}>
                {
                  <TextFormField
                    label={'Company'}
                    name={'company'}
                    placeholder={''}
                    type={'text'}
                    required={false}
                    fullWidth={false}
                  />
                }
              </div>

              <div className={formStyles.formWrapper}>
                <FormFieldsContainer>
                  <TextAreaFormField
                    required={false}
                    name={'projectInfo'}
                    placeholder={'About your project'}
                    label={'Please tell us a little bit more about your project'}
                    fullWidth={true}
                  />
                </FormFieldsContainer>
              </div>

              <Button
                icon={'right-arrow'}
                variant={'secondary'}
                disabled={isSubmitting}
                type="submit"
                className={'center'}
                isLoading={isSubmitting}
              >
                Submit an enquiry
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
