import Image from 'next/image'
import React, { PropsWithChildren } from 'react'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { Gallery } from './lightbox'
import classes from './embeddableVideo.module.scss'

export type EmbeddedVideoProps = {
    video: string
    thumbnail: string
    mediaImage: string

}

const EmbeddableVideo: React.FC<PropsWithChildren<EmbeddedVideoProps>> = ({ video, thumbnail, mediaImage }) => {  
  return (
    <div 
      className={classes.container}
    >
      <div className={classes.embededVideoContainer}>
        <div className={classes.videoContainer}>
          <Image src={`${mediaImage?mediaImage:thumbnail}${IMAGE_FILTER}`} alt="maskImage" width={782} height={523}/>
          <div className={classes.playBtn}>
            <Gallery videoLink={video}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmbeddableVideo