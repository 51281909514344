import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import styles from './FCPImage.module.scss'

export type FCPImageProps = {
  src: {
    filename: string
    alt: string
    title?: string
  }
  size: 'default' | '90' | '80' | '70' | '60' | '50' | '40' | '30' | '20' | '10' | '5'
  mob_size: 'default' | '90' | '80' | '70' | '60' | '50' | '40' | '30' | '20' | '10' | '5'
  variant: 'default' | 'circle' | 'rectangle'
  align: 'center' | 'left' | 'right'
  full_width: boolean
  href?: string
}

type WrapperType = {
  link?: string
  className?: string[]
  children: React.ReactNode
}

const ImageWrapper: React.FC<PropsWithChildren<WrapperType>> = ({ link, className, children }) => (
  link ? (
    <a className={classnames(className)} href={link}>
      {children}
    </a>
  ) : (
    <div className={classnames(className)}>
      {children}
    </div>
  )
)

export const FCPImage: React.FC<FCPImageProps> = ({
  src,
  variant,
  size,
  mob_size,
  align,
  full_width,
  href,
}) => {
  const imageClasses = []

  if (full_width) imageClasses.push(styles['fullWidth'])

  if (variant) imageClasses.push(styles[`imgType-${variant}`])

  if (size) imageClasses.push(styles[`imgSize-${size}`])
  if (mob_size) imageClasses.push(styles[`imgMobSize-${mob_size}`])

  return !src?.filename ? null : (
    <div className={classnames(styles['imgWrapper'], styles[`imgAlign-${align}`])}>
      <div className={classnames(imageClasses)}>
        <ImageWrapper link={href}>
          {src?.filename.split('.').pop() !== 'svg' ? (
            <img src={`${src?.filename}${IMAGE_FILTER}`} alt={src?.alt} />
          ) : (
            <object data={`${src?.filename}`} type="image/svg+xml" width="100%" height="100%">image</object>
          )}
          {src.title !== '' ? (<div className={styles.imageTitle}>{src.title}</div>) : null}
        </ImageWrapper>
      </div>
    </div>
  )
}
