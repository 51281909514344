import { UnOrderedListItem, UnOrderedListItemProps } from './UnOrderedListItem'
import React, { PropsWithChildren } from 'react'

export type UnOrderedListWrapperProps = React.ComponentProps<'ul'> & {
  //
}

export type UnOrderedListProps =
  | { item: true } & UnOrderedListItemProps
  | { item?: false } & UnOrderedListWrapperProps

export const UnOrderedList: React.FC<PropsWithChildren<UnOrderedListProps>> = ({ item = false, ...props }) => {
  if (item) {
    return (
      <UnOrderedListItem { ...props } />
    )
  }

  return (
    <ul className="unorderedList" { ...props } />
  )
}
