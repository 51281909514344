import { FieldHookConfig, useField } from 'formik'
import React, { PropsWithChildren } from 'react'
import formStyles from '../forms.module.scss'
import { FormErrorWrapper } from './FormErrorWrapper'
import { FormFieldWrapper } from './FormFieldWrapper'

type TextFormFieldProps = {
  label: string
  placeholder: string
  type: 'text' | 'password' | 'email' | 'number'
  required?: boolean
  errorText?: string
  fullWidth?: boolean
} & FieldHookConfig<[]>;

export const TextFormField: React.FC<PropsWithChildren<TextFormFieldProps>> = ({
  label,
  placeholder,
  type,
  required = false,
  fullWidth = false,
  ...props
}) => {
  const [field, meta] = useField<[]>(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  const inputFieldClass = errorText
    ? `${formStyles.textInput} ${formStyles.error}`
    : `${formStyles.textInput}`

  return (
    <FormFieldWrapper fullWidth={fullWidth}>
      <div className={formStyles.inputFieldWrapper}>
        <label htmlFor={props.id || props.name}>
          {label}
          {required && <sup className={formStyles.required}>*</sup>}
        </label>
        <input
          className={inputFieldClass}
          {...field}
          placeholder={placeholder}
          type={type}
        />
        {errorText && <FormErrorWrapper>{errorText}</FormErrorWrapper>}
      </div>
    </FormFieldWrapper>
  )
}
