import { PropsWithChildren } from 'react'
import classes from './inlineLogo.module.scss'

type LogoProps = {
  logo_img: {
    filename: string
    alt: string
  }
}

export type LogoWrapperProps = React.ComponentProps<'ul'> & {
  logos: LogoProps[]
}

export const InlineLogo: React.FC<PropsWithChildren<LogoWrapperProps>> = ({ ...props }) => {
  const logos = props.logos

  return (
    <div>
      <ul className={classes.inlineLogo}>
        {logos.map((logo, i: number) => {
          return (
            <li key={i} className={classes.listItem}>
              <img src={logo?.logo_img?.filename} alt={logo?.logo_img?.alt} />
            </li>
          )
        })
        }
      </ul>
    </div>
  )
}
