import React from 'react'
import { FadeInWhenVisible } from '../../FadeInWhenVisible'
import formStyles from '../forms.module.scss'

type FormErrorWrapperProps = {
  children: React.ReactNode
};

export const FormErrorWrapper: React.VFC<FormErrorWrapperProps> = ({
  children,
}) => {
  return (
    <FadeInWhenVisible
      animation="easyFadeIn"
      transition={{ delay: 0.1, duration: 0.5 }}
      everyVisible
    >
      <div className={formStyles.errorText}>{children}</div>
    </FadeInWhenVisible>
  )
}
