import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { OrderedList, OrderedListProps } from './OrderedList'
import { OrderedListItemProps } from './OrderedListItem'

type DataNode = OrderedListItemProps & {
  text?: string
  color?: string
  link?: boolean
  linkHref?: string
  linkTarget?: boolean
  key?: number
}

type DataProps = {
  items?: DataNode[]
  color?: string
}

export const RenderOrderedListItems:React.FC<PropsWithChildren<DataProps>> = ({ items, color })=>{
  return (
    <>
      {
        items && items?.length !== 0 && items?.map(({ text, ...itemNode }, index) => {
          const listItemProps: OrderedListProps = {
            item: true,
            ...itemNode,
          }

          if (color !== undefined) {
            listItemProps.color = color
          }

          let child: string | React.ReactNode = (<p style={{ fontWeight: 300, color: '#707070' }}>{text}</p>)

          if (listItemProps.link) {
            child = (
              <Link href={listItemProps.linkHref !== undefined ? `/${listItemProps.linkHref}` : '#'} passHref>
                <a target={listItemProps.linkTarget ? '_blank' : '_self'} rel="noreferrer">
                  <p>
                    {text}
                  </p>
                </a>
              </Link>
            )
          }

          return (
            <OrderedList key={index} {...listItemProps} >
              {child}
            </OrderedList>
          )
        })
      }
    </>
  )
}
