import { OrderedListItem, OrderedListItemProps } from './OrderedListItem'
import styles from './orderedlist.module.scss'
import React, { PropsWithChildren } from 'react'

export type OrderedListWrapperProps = React.ComponentProps<'ol'> & {
  //
}

export type OrderedListProps =
  | { item: true } & OrderedListItemProps
  | { item?: false } & OrderedListWrapperProps

export const OrderedList: React.FC<PropsWithChildren<OrderedListProps>> = ({ item = false, ...props }) => {
  if (item) {
    return (
      <OrderedListItem { ...props } />
    )
  }

  return (
    <ol className={styles.customOrderedList} { ...props } />
  )
}
