import { default as classNames, default as classnames } from 'classnames'
import React, { CSSProperties, PropsWithChildren } from 'react'
import AtomixIcon, { IconCodeType } from '../icons/AtomixIcon'
import classes from './unorderedlist.module.scss'

export type UnOrderedListItemProps = {
  iconCode?: IconCodeType
  rotateIcon?: string
  color?: string
  link?:  boolean // if true, the styling will change accordingly
  linkHref?: string
  linkTarget?: boolean
  blob?: {
    plugin: 'list-blob'
    blob: boolean
    rotate: 0 | 90 | 180 | 270
  }
  style?: CSSProperties
  rotate?: 0 | 90 | 180 | 270
}

export const UnOrderedListItem: React.FC<PropsWithChildren<UnOrderedListItemProps>> = ({
  children,
  link,
  linkHref,
  iconCode = 'butterfly',
  blob,
  color = 'green',
}) => {
  const classNameString = (blob?.blob) ? 'list-item-button' : 'list-item'
  const iconClassList = [classes[`${classNameString}__circle`]]
  const iconSpanClassList = [classes[`${classNameString}__icon`]]
  const liClassList = [classes[classNameString]]
  const liSpanClassList = [classes[`${classNameString}__content`]]

  if (link) {
    iconClassList.push(classes[`${classNameString}__circle--link`])
    iconSpanClassList.push(classes[`${classNameString}__icon--link`])
    liClassList.push(classes[`${classNameString}--link`])
    liSpanClassList.push(classes[`${classNameString}__content--link`])
  }

  if (color) {
    iconSpanClassList.push(classes[`${classNameString}__icon--${color}`])
  }

  if (link && color) {
    iconClassList.push(classes[`${classNameString}__circle--link-${color}`])
    iconSpanClassList.push(classes[`${classNameString}__icon--link--${color}`])
    liSpanClassList.push(classes[`${classNameString}__content--link-${color}`])
  }

  switch (blob?.rotate) {
  case 90:
    iconClassList.push(classes[`${classNameString}__circle__rotate_90`])
    break
  case 180:
    iconClassList.push(classes[`${classNameString}__circle__rotate_180`])
    break
  case 270:
    iconClassList.push(classes[`${classNameString}__circle__rotate_270`])
    break
  default:
    break
  }

  // Icon in front of text
  const iconPrefix = (
    <div
      className={
        classNames(
          classes[`${classNameString}__icon-wrapper`],
        )
      }
    >
      {link && linkHref && linkHref !== '' ? (
        <a href={linkHref} className={classes['iconBlobLink']}>
          {blob?.blob && <AtomixIcon
            iconCode="egg"
            className={classnames(iconClassList)}
          >
          </AtomixIcon>}
          <span
            className={classnames(iconSpanClassList)}
          >
            <AtomixIcon iconCode={iconCode} className={classes['list-item__svg']} />
          </span>
        </a>
      ) : (
        <>
          {blob?.blob && <AtomixIcon
            iconCode="egg"
            className={classnames(iconClassList)}
          >
          </AtomixIcon>}
          <span
            className={classnames(iconSpanClassList)}
          >
            <AtomixIcon iconCode={iconCode} className={classes['list-item__svg']} />
          </span>
        </>
      )}
    </div>
  )

  return (
    <li
      className={
        classnames(liClassList)
      }
    >
      { iconPrefix }
      <span
        className={classnames(liSpanClassList)}
      >
        { children }
      </span>
    </li>
  )
}
