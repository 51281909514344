import React from 'react'
import formStyles from '../forms.module.scss'

type FormFieldWrapperProps = {
  children: React.ReactNode
  fullWidth?: boolean
};

export const FormFieldWrapper: React.VFC<FormFieldWrapperProps> = ({
  children,
  fullWidth,
}) => {
  const formFieldWrapperStyles = fullWidth
    ? `${formStyles.formFieldWrapper} ${formStyles.fullWidth}`
    : `${formStyles.formFieldWrapper}`

  return <div className={formFieldWrapperStyles}>{children}</div>
}
