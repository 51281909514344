import React from 'react'
import formStyles from '../forms.module.scss'

type FormFieldsContainerProps = {
  children: React.ReactNode
};

export const FormFieldsContainer: React.VFC<FormFieldsContainerProps> = ({
  children,
}) => {
  return <div className={formStyles.formFieldsContainer}>{children}</div>
}
