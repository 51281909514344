import classnames from 'classnames'
import Image from 'next/image'
import React, { PropsWithChildren } from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import classes from './index.module.scss'


type ImageCompareSliderProps = {
  component: string
  device_type: string
  before_image: {
    filename: string
  }
  after_image: {
    filename: string
  }
}

const ImageCompareSlider:React.FC<PropsWithChildren<{slice: ImageCompareSliderProps}>> = ({ slice })=> {
  const imageSliderClassContainer = [classes['imageSliderClassContainer']]
  const imageSliderClass = [classes['compareWrapper']]

  return (
    <div className={classnames(imageSliderClassContainer)}>
      <div className={classnames(imageSliderClass)}>
        {slice.device_type === 'desktop' ?
          (
            <div className={classes.deviceFrame}>
              <Image
                src="/images/inFrameCarousel/screen-mockup_black@2x.png"
                layout="responsive"
                width={958}
                height={551}
                alt={'device-frame'}
                unoptimized={!!process?.env?.STORYBOOK}
              />
              <div
                className={classes.screen}
              >
                <ReactCompareSlider
                  changePositionOnHover
                  itemOne={<ReactCompareSliderImage src={slice?.before_image.filename} srcSet={slice?.before_image.filename} alt="Before image" />}
                  itemTwo={<ReactCompareSliderImage src={slice?.after_image.filename} srcSet={slice?.after_image.filename} alt="After image" />}
                />
              </div>
            </div>
          ) : (
            <div className={classes.deviceFrameMobile}>
              <Image
                src="/images/inFrameCarousel/screen-mockup-mobile-dark.png"
                layout="responsive"
                width={425}
                height={800}
                alt={'device-frame'}
                unoptimized={!!process?.env?.STORYBOOK}
              />
              <div
                className={classes.screen}
              >
                <ReactCompareSlider
                  changePositionOnHover
                  itemOne={<ReactCompareSliderImage src={slice?.before_image.filename} srcSet={slice?.before_image.filename} alt="Before image" />}
                  itemTwo={<ReactCompareSliderImage src={slice?.after_image.filename} srcSet={slice?.after_image.filename} alt="After image" />}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default ImageCompareSlider
