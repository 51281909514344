import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import btnPlayStyles from './btnPlay.module.scss'

type ButtonType = {
  hover?: boolean
}

export const BtnPlay: React.FC<PropsWithChildren<ButtonType>> = ({ hover }) => {
  const btnClasses = [btnPlayStyles.btnPlay]

  if (hover) btnClasses.push(btnPlayStyles.btnPlayHover)

  return (
    <div className={classNames(btnClasses)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23.524" height="25.212" viewBox="0 0 23.524 25.212">
        <g id="Group_2737" data-name="Group 2737" transform="translate(-2249.332 -2283.401)">
          <path id="Path_6533" data-name="Path 6533" d="M2249.677,2285.256l18,10.1,2.6,1.459.169-1.448L2253.5,2305.52l-2.422,1.452,2.418.51-1.077-20.249-.155-2.915c-.079-1.48-3-1-2.931.24l1.078,20.249.155,2.915c.066,1.251,1.686.948,2.418.51l16.941-10.154,2.422-1.451c.481-.288.773-1.109.169-1.448l-18-10.1-2.6-1.459a1.973,1.973,0,0,0-2.074.188C2249.41,2284.136,2249.035,2284.9,2249.677,2285.256Z"/>
        </g>
      </svg>
    </div>
  )
}
