import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { ISbComponentType } from 'storyblok-js-client'
import { Column, ColumnProps } from '../../../../components/Column'
import { FlexibleContent } from '../../../../components/FlexibleContent'
import { Grid } from '../../../../components/Grid'
import columnClasses from './columns.module.scss'

export type ColumnsProps = {
    component: string
    _uid: string
    content: Array<ColumnProps | ColumnsSliceProps>
    extra_gap_select?: '' | 'extra' | 'extraExtra'
    row_width?: 'standard' | 'constrained' | 'wide' | 'blog' | 'project'
    align_vertical?: boolean
    wider_column?: '' | 'left' | 'right'
    reverse_row?: boolean
    one_col_width?: '' | '40' | '50' | '60' | '70' | '80'
  }

export type ColumnsSliceProps = ISbComponentType<'nested_columns'> & ColumnsProps

export const Columns: React.FC<PropsWithChildren<{slice: ColumnsProps; accordion?: boolean; customClass?: string}>> = ({ slice, accordion, customClass }) => {
  const { content, align_vertical, wider_column, row_width, extra_gap_select, reverse_row, one_col_width } = slice
  const gridClassList = accordion ? [] : [columnClasses.standard]

  if (content) gridClassList.push(columnClasses.mobileGap)

  if (reverse_row) gridClassList.push(columnClasses.rowReverse)

  if (extra_gap_select === 'extra') gridClassList.push(columnClasses.extraGap)

  if (extra_gap_select === 'extraExtra') gridClassList.push(columnClasses.extraExtraGap)

  if (row_width === 'constrained') gridClassList.push(columnClasses.constrained)

  if (row_width === 'blog') gridClassList.push(columnClasses.blogConstrained)

  if (row_width === 'project') gridClassList.push(columnClasses.projectConstrained)

  if (row_width == 'wide') gridClassList.push(columnClasses.wide)

  if (align_vertical) gridClassList.push(columnClasses.alignVertical)

  if (wider_column === 'left') gridClassList.push(columnClasses.leftOverflow)

  if (wider_column === 'right') gridClassList.push(columnClasses.rightOverflow)

  switch (one_col_width) {
  case '40':
    gridClassList.push(columnClasses.fourtyWidth)
    break
  case '50':
    gridClassList.push(columnClasses.fiftyWidth)
    break
  case '60':
    gridClassList.push(columnClasses.sixtyWidth)
    break
  case '70':
    gridClassList.push(columnClasses.seventyWidth)
    break
  case '80':
    gridClassList.push(columnClasses.eightyWidth)
    break
  default:
    break
  }

  return !content ? null : (
    <FlexibleContent column>
      <Grid className={classnames(gridClassList)}>
        {content && content.length > 0 && content.map((props) => {
          switch (props.component) {
          case 'column':
            // @ts-ignore
            return <Column customClass={customClass} { ...props } key={props._uid} />
          default:
            return null
          }
        })}
      </Grid>
    </FlexibleContent>
  )
}


export default Columns
