import classnames from 'classnames'
import React, { CSSProperties, PropsWithChildren } from 'react'
import classes from './orderedlist.module.scss'

export type OrderedListItemProps = {
  color?: string
  link?: boolean
  linkHref?: string
  linkTarget?: boolean
  iconButton?: boolean
  style?: CSSProperties
}

export const OrderedListItem: React.FC<PropsWithChildren<OrderedListItemProps>> = ({
  children,
  link,
  color = 'green',
}) => {
  const orderStyles = [classes['list-item__order']]
  const contentStyles = [classes['list-item__content']]
  const listItemStyles = [classes['list-item']]

  switch (color) {
  case 'green':
    listItemStyles.push(classes.listStyleGreen)
    break
  case 'yellow':
    listItemStyles.push(classes.listStyleYellow)
    break
  case 'red':
    listItemStyles.push(classes.listStyleRed)
    break
  case 'orange':
    listItemStyles.push(classes.listStyleOrange)
    break
  case 'purple':
    listItemStyles.push(classes.listStylePurple)
    break
  default:
    break
  }

  if(color) {
    contentStyles.push(classes[`list-item__content--link-${color}`])
  }

  if (link) {
    orderStyles.push(classes['list-item__order--link'])
    contentStyles.push(classes['list-item__content--link'])
  }

  return (
    <li className={classnames(listItemStyles)}>
      <span
        className={classnames(orderStyles)}
      />
      <span className={classnames(contentStyles)}>
        { children }
      </span>
    </li>
  )
}
