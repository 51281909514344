import { FieldHookConfig, useField } from 'formik'
import React, { PropsWithChildren } from 'react'
import formStyles from '../forms.module.scss'
import { FormErrorWrapper } from './FormErrorWrapper'
import { FormFieldWrapper } from './FormFieldWrapper'

type TextAreaFormFieldProps = {
  label: string
  placeholder: string
  required?: boolean
  errorText?: string
  fullWidth?: boolean
} & FieldHookConfig<[]>;

export const TextAreaFormField: React.FC<PropsWithChildren<TextAreaFormFieldProps>> = ({
  label,
  placeholder,
  required = false,
  fullWidth = false,
  ...props
}) => {
  const [field, meta] = useField<[]>(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  const inputFieldClass = errorText
    ? `${formStyles.textAreaInput} ${formStyles.error}`
    : `${formStyles.textAreaInput}`

  return (
    <FormFieldWrapper fullWidth={fullWidth}>
      <div className={formStyles.inputFieldWrapper}>
        <label htmlFor={props.id || props.name}>
          {label}
          {required && <sup className={formStyles.required}>*</sup>}
        </label>
        <textarea
          className={inputFieldClass}
          {...field}
          placeholder={placeholder}
        />
        {errorText && <FormErrorWrapper>{errorText}</FormErrorWrapper>}
      </div>
    </FormFieldWrapper>
  )
}
