import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { FadeInWhenVisible } from '../FadeInWhenVisible'
import { Quote } from './Quote'
import classes from './QuoteVariant.module.scss'

export type QuoteVariantProps = React.ComponentProps<'p'> & {
  className?: string
  author: string
  position: string
  isDarkMode?: boolean
}

export const QuoteVariant: React.FC<PropsWithChildren<QuoteVariantProps>> = ({
  children,
  className,
  author,
  position,
  isDarkMode,
}) => {
  const quoteClasses = [className]

  if (isDarkMode) {
    quoteClasses.push(classes.quoteVariant_inner)
  } else {
    quoteClasses.push(classes.quoteVariant)
  }

  return (
    <div
      className={classnames(quoteClasses)}>
      <FadeInWhenVisible
        transition={{ duration: 0.5, delay: 0.3 }}
        exitAnimation={{ opacity: 0.5, y: 20 }}
        animation="easyFadeInStill"
      >
        <Quote isDarkMode={isDarkMode} className={classnames(className, classes.quote)}> {children} </Quote>
        <div className={classnames(className, classes.wrapper)}>
          <span className={classes.author}>
            {author}
          </span>
          <span className={classes.position}>
            {position}
          </span>
        </div>
      </FadeInWhenVisible>
    </div>
  )
}
