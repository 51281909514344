/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-video.css'
import styles from '../HomeVideoLightbox/lightgallery/lightgallery.module.scss'
import { BtnPlay } from '../HomeVideoLightbox/btnPlay/btnPlay'
import classnames from 'classnames'
import { IHomeVideoLightboxProps } from '../HomeVideoLightbox'

export const Gallery = (props: IHomeVideoLightboxProps) => {
  const { videoLink, customCtaText, customCtaLink, customLinkTarget, customClass } = props

  const renderCustomCtaButton = customCtaText !== '' ? `<div class="light-gallery-link"><a class="video-text" href='${customCtaLink}' target='${customLinkTarget}'>${customCtaText}</a><div class="bottom-border"></div></div>` : '<div></div>'

  return (
    <div className={classnames(styles.galleryContainer, [styles[`${customClass}`]])}>
      <LightGallery
        addClass={styles.galleryWrapper}
        plugins={[lgZoom, lgVideo]}
        mode="lg-fade"
        speed={80}
        startAnimationDuration={10}
        enableDrag={false}
        enableSwipe={false}
        hideControlOnEnd={true}
        download={false}
        loadYouTubePoster={false}
        youTubePlayerParams={{
          autoplay: 1,
          modestbranding: 1,
          showinfo: 0,
          controls: 1,
          allowfullscreen: 1,
          rel: 0,
          mute:0,
        }}
        licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
      >
        <button
          aria-label={'play video'}
          className={styles.button}
          data-lg-size="1280-720"
          data-src={videoLink}
          data-sub-html={customCtaText !== undefined ? renderCustomCtaButton : ''}
        >
          <BtnPlay />
        </button>
      </LightGallery>
    </div>
  )
}
