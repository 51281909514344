import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import classes from './quote.module.scss'

export type QuoteWrapperProps = React.ComponentProps<'p'> & {
  isDarkMode?: boolean
}

export const Quote: React.FC<PropsWithChildren<QuoteWrapperProps>> = ({
  isDarkMode,
  ...props
}) => {
  const quoteClasses = [classes.link]

  if (isDarkMode) {
    quoteClasses.push(classes.quote_text_inner)
  } else {
    quoteClasses.push(classes.quote_text)
  }

  return (
    <p
      className={classnames(quoteClasses)}
      { ...props } />
  )
}
