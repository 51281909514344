/* eslint-disable no-case-declarations */
import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { FAQPage, WithContext } from 'schema-dts'
import accordionClasses from '../../slices/FlexibleContent/slices/Accordion/Accordion.module.scss'
import Columns from '../../slices/FlexibleContent/slices/Columns'
import { isEmpty } from '../../utils/isEmpty'
import { nonNull } from '../../utils/nonNull'
import { useRichText } from '../../utils/useRichText'
import { Accordion } from '../Accordion'
import { CustomLink, CustomLinkProps } from '../CustomLink'
import { DashedList } from '../DashedList/DashedList'
import EmbeddableVideo from '../EmbeddableVideo'
import { FCPImage, FCPImageProps } from '../FCPImage'
import { FlexibleContent } from '../FlexibleContent'
import { AtomixFullForm } from '../Forms/AtomixFullForm'
import { Grid } from '../Grid'
import { GridSizes } from '../Grid/Grid'
import ImageCompareSlider from '../ImageCompareSlider'
import { InlineLogo } from '../InlineLogo'
import { OrderedList } from '../OrderedList'
import { RenderOrderedListItems } from '../OrderedList/RenderOrderedListItems'
import { Quote } from '../Quote/Quote'
import { QuoteVariant } from '../Quote/QuoteVariant'
import { StructuredData } from '../StructuredData'
import { UnOrderedList } from '../UnOrderedList'
import { RenderListItems } from '../UnOrderedList/RenderListItems'
import {
  BlockquoteType,
  BlokType,
  BulletListType,
  HeadingType,
  HorizontalRuleType,
  OrderedListType,
  ParagraphType,
} from './blokTypes'
import classes from './column.module.scss'
import { SbBlokData } from '@storyblok/react'

type  CustomGridProps = {
  component?: string
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
  _editable?: string
  _uid?: string
}

export type ColumnProps = Omit<CustomGridProps, 'as'> &
  SbBlokData &
  CustomLinkProps &
  FCPImageProps & {
  body?: {
    content?: (HeadingType|ParagraphType|HorizontalRuleType|BulletListType|OrderedListType|BlockquoteType|BlokType)[]
    type?: string
  }
  customClass?: string
}

export const Column: React.FC<PropsWithChildren<ColumnProps>> = ({ body, className, md, sm, xs, lg, customClass }) => {
  const contentData = body?.content

  return contentData && contentData?.length > 0 ? (
    <Grid item as="section" className={classnames(classes.column, className)} md={md} sm={sm} xs={xs} lg={lg} >
      {contentData?.map((contentItem: HeadingType | ParagraphType | HorizontalRuleType | BulletListType | OrderedListType | BlockquoteType | BlokType, i: number) => {
        switch (contentItem.type) {
        case 'paragraph':
          const paraContent = useRichText(contentItem)
          const html = paraContent?.dangerouslySetInnerHTML?.__html

          return <p key={i}>{ ReactHtmlParser(html) }</p>

        case 'heading':
          const headingType = contentItem?.attrs?.level
          const headerContent = useRichText(contentItem)
          const headerHtml = headerContent?.dangerouslySetInnerHTML?.__html

          switch (headingType) {
          case 2:
            return (
              <h2 key={i}>
                {ReactHtmlParser(headerHtml)}
              </h2>
            )
          case 3:
            return (
              <h3 key={i}>
                {ReactHtmlParser(headerHtml)}
              </h3>
            )
          case 4:
            return (
              <h4 key={i}>
                {ReactHtmlParser(headerHtml)}
              </h4>
            )
          case 5:
            return (
              <h5 key={i}>
                {ReactHtmlParser(headerHtml)}
              </h5>
            )
          case 6:
            return (
              <h6 key={i}>
                {ReactHtmlParser(headerHtml)}
              </h6>
            )
          }

          break

        case 'bullet_list':
          const bulletContent = useRichText(contentItem)
          const bulletHtml = bulletContent?.dangerouslySetInnerHTML?.__html

          return (
            <ul key={i}>
              {ReactHtmlParser(bulletHtml)}
            </ul>
          )

        case 'ordered_list':
          const orderedContent = useRichText(contentItem)
          const orderedHtml = orderedContent?.dangerouslySetInnerHTML?.__html

          return (
            <ol key={i}>
              {ReactHtmlParser(orderedHtml)}
            </ol>
          )

        case 'blockquote':
          const quoteText = contentItem?.content?.[0]?.content?.[0]?.text

          return (
            <Quote key={i}>{quoteText}</Quote>
          )

        case 'horizontal_rule':
          return (
            <hr key={i} />
          )

        case 'blok':
          // eslint-disable-next-line no-case-declarations
          const bloks = contentItem?.attrs?.body

          return bloks.map((blokData, _) => {
            switch (blokData?.component) {
            // Nested Columns
            case 'nested_columns':
              return (
                // @ts-ignore
                <Columns  slice={{ reverse_row: blokData.reverse_row, content: blokData.content, align_vertical: blokData.align_vertical, row_width: 'wide', component: 'columns', _uid: blokData._uid }}/>
              )

              // FCP Button Link
            case 'fcp_button_link':
              return (
                <CustomLink
                  key={i}
                  href={blokData?.button_link?.cached_url || '#'}
                  variant={blokData?.button_variant}
                  inverseColor={blokData?.inverseColor}
                  iconColor={blokData?.button_variant === 'list-style' ? blokData?.iconColor?.[0]?.color : ''}
                  alignment = {blokData?.alignment}
                  isFCP={true}
                >
                  {blokData?.button_title}
                </CustomLink>
              )

              // FCP Custom List
            case 'fcp_custom_list':
              const unOrderedListItems = blokData && (nonNull(blokData.content).map((item, i: number) => {
                return {
                  iconCode: item?.iconCode,
                  text: item?.text,
                  color: blokData.color ? (blokData?.color) : (item?.colour),
                  link: item?.itemLink?.cached_url !== '',
                  linkHref: item?.itemLink?.cached_url,
                  linkTarget: item?.new_tab,
                  blob: item?.blob,
                  rotate: item?.blob?.rotate,
                  key:i,
                }
              }))

              return (
                <UnOrderedList key={i}>
                  <RenderListItems
                    key={i}
                    items={unOrderedListItems}
                    color={blokData?.color ? blokData?.color : undefined} />
                </UnOrderedList>
              )

              // FCP Ordered List
            case 'fcp_custom_ordered_list':
              const orderedListItems = blokData && (nonNull(blokData.content).map((item, i: number) => {
                return {
                  text: item?.text?.content?.[0]?.content?.[0]?.text,
                  color: item?.iconColor,
                  link: item?.isLink,
                  linkHref: item?.itemLink?.cached_url,
                  linkTarget: false,
                  key:i,
                }
              }))

              return (
                <OrderedList key={i}>
                  <RenderOrderedListItems
                    key={i}
                    items={orderedListItems}
                    color={blokData?.color ? blokData?.color : undefined} />
                </OrderedList>
              )

              // FCP Accordions
            case 'accordion':
              const sizes: Partial<GridSizes> = {}
              const classes = []
              let structuredData: WithContext<FAQPage> | undefined

              if (blokData?.showStructuredData && blokData?.panels) {
                structuredData = {
                  '@context': 'https://schema.org',
                  '@type': 'FAQPage',
                  mainEntity: blokData?.panels.filter(({ structuredTitle, structuredContent }) => {
                    return (!isEmpty(structuredTitle) && !isEmpty(structuredContent))
                  }).map(({ structuredTitle, structuredContent }) => {
                    return {
                      '@type': 'Question',
                      name: structuredTitle,
                      acceptedAnswer: {
                        '@type': 'Answer',
                        text: structuredContent,
                      },
                    }
                  }),
                }
              }

              if (blokData?.constrained) {
                sizes.md = 10
                sizes.lg = 9
                sizes.xl = 8

                switch (blokData?.alignment) {
                case 'centre':
                  classes.push(accordionClasses['constrained--center'])
                  break
                case 'left':
                  classes.push(accordionClasses['constrained--left'])
                  break
                case 'right':
                  classes.push(accordionClasses['constrained--right'])
                  break
                }
              }

              return (
                <FlexibleContent key={i} column { ...sizes } className={classnames(classes)}>
                  {structuredData && <StructuredData schema={structuredData}/>}
                  <Accordion customClass={customClass}>
                    {blokData?.panels.map(({ title, content, _uid }, i) => {
                      return (
                        <Accordion isDarkMode={blokData?.isDarkMode} panel key={_uid} i={i + 1}>
                          <Accordion isDarkMode={blokData?.isDarkMode} title>
                            {title}
                          </Accordion>
                          <Accordion isDarkMode={blokData?.isDarkMode} content as={FlexibleContent} container>
                            {content.map((slice) => (
                              <FlexibleContent row key={slice._uid}>
                                <Columns accordion={true} slice={slice} />
                              </FlexibleContent>
                            ))}
                          </Accordion>
                        </Accordion>
                      )
                    })}
                  </Accordion>
                </FlexibleContent>
              )

              // FCP Inline Logos
            case 'fcp_inline_logos':
              return (
                <InlineLogo key={i} logos={blokData?.logos} />
              )

              // FCP Blockquote with author
            case 'fcp_blockquote_variant':
              return (
                <QuoteVariant key={i} author={blokData?.author} position={blokData?.position} isDarkMode={blokData?.is_darkmode}>
                  {blokData.quote_text}
                </QuoteVariant>
              )

              // FCP Dashed List
            case 'fcp_dashed_list':
              return (
                <DashedList key={i} items={blokData?.items} />
              )

              // FCP Image
            case 'fcp_image':
              return (
                <FCPImage key={i} href={blokData?.link?.cached_url} src={blokData?.image_src} variant={blokData?.image_variant} size={blokData?.image_size} mob_size={blokData?.mob_size} align={blokData?.image_align} full_width={blokData?.full_width} />
              )

              // FCP Embeddable Video
            case 'embedable_video':
              return blokData?.media?.filename && typeof blokData?.video === 'string' && (
                <EmbeddableVideo
                  mediaImage={blokData?.media.filename}
                  video={blokData?.video}
                  thumbnail={`https://img.youtube.com/vi/${blokData?.video.split('v=')[1]}/0.jpg`}
                />
              )

              // FCP Form
            case 'get_in_touch_form':
              return (
                <AtomixFullForm slice={blokData} />
              )

              // FCP Compare slider
            case 'image_compare_slider':
              return (
                <FlexibleContent row customClass={customClass}>
                  <ImageCompareSlider slice={blokData} />
                </FlexibleContent>
              )

            default:
              return null
            } // End - blok case
          })


        default:
          return null
        } // End - content case
      })}
    </Grid>
  ) : null
}
