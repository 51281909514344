import Link from 'next/link'
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ISbRichtext } from 'storyblok-js-client'
import { useRichText } from '../../utils/useRichText'
import { IconCodeType } from '../icons/AtomixIcon'
import { UnOrderedList, UnOrderedListProps } from './UnOrderedList'
import { UnOrderedListItemProps } from './UnOrderedListItem'

type DataNode = {
  iconCode?: IconCodeType
  rotateIcon?: string
  color?: string
  link?:  boolean // if true, the styling will change accordingly
  linkHref?: string
  linkTarget?: boolean
  blob: {
    plugin: 'list-blob'
    blob: boolean
    rotate: 0 | 90 | 180 | 270
  }
  style?: CSSProperties
  text?: ISbRichtext
}

type DataProps = {
  items?: DataNode[]
  color?: string
  buttonEnabled?: boolean
}

type WrapperProps = {
  link?: boolean
  children: ReactNode
  listItemProps: UnOrderedListItemProps
}

const Wrapper: React.FC<PropsWithChildren<WrapperProps>> = ({ listItemProps, link, children }) => {
  return link ? (
    <Link
      href={listItemProps.linkHref !== undefined ? `${listItemProps.linkHref}` : '#'}
      passHref
    ><a href="" style= {{ textDecoration: 'none' }}>
        {children}
      </a>
    </Link>
  ) : (
    <p style={{ fontWeight: 300, color: '#707070', marginBottom: 0 }}>
      {children}
    </p>
  )
}

export const RenderListItems:React.FC<PropsWithChildren<DataProps>> = ({ items, color })=> (
  <>
    {
      items && items?.length !== 0 && items?.map(({ text, ...itemNode }, index) => {
        const listItemProps: UnOrderedListProps = {
          item: true,
          ...itemNode,
        }

        if (color !== undefined) {
          listItemProps.color = itemNode.color || color
        }

        const contentText = text ? useRichText(text) : null
        const listHtml = contentText?.dangerouslySetInnerHTML?.__html

        return listHtml && (
          <UnOrderedList key={index} {...listItemProps} >
            <Wrapper listItemProps={listItemProps} link={listItemProps.link} >
              {ReactHtmlParser(listHtml)}
            </Wrapper>
          </UnOrderedList>
        )
      })
    }
  </>
)
