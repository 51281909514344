import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import classes from './dashedlist.module.scss'

export type ListItemProps = React.ComponentProps<'li'> & {
  text: string
  variant?: string
  link?: string
}

export const ListItem: React.FC<PropsWithChildren<ListItemProps>> = ({ ...props }) => {
  const variant = props?.variant
  const text = props?.text
  const link = props?.link

  return (
    <>
      {variant === 'default' && (
        <li className={classes.normalLi}>{text}</li>
      )}
      {variant === 'strong' && (
        <li className={classes.strongLi}><strong>{text}</strong></li>
      )}
      {variant === 'link' && (
        <li className={classes.normalLi}><Link href={`${link}` || '#'}>{text}</Link></li>
      )}
    </>
  )
}
